import * as React from 'react';
import { Display } from "./Display";
import { MuiThemeProvider, Grid, CircularProgress, createMuiTheme, Dialog, DialogTitle, IconButton, DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AddGuestForm } from './CheckinKiosk/AddGuestForm';
import { CssBaseline } from '@material-ui/core';
import { ApiUtils } from '../../Services/ApiUtils';
import { RegistrationForm } from './CheckinKiosk/RegistrationForm';
import { LoginForm } from './CheckinKiosk/LoginForm';
import { CustomerCheckin } from './CheckinKiosk/CustomerCheckin';
import { GroupSelector } from './CheckinKiosk/GroupSelector';
import CheckinCompletion from './CheckinKiosk/CheckinCompletion';
import KioskError from './CheckinKiosk/KioskError';
import ClosedShop from './CheckinKiosk/ClosedShop';
import CountrySelection from "./CheckinKiosk/CountrySelector";
export var KioskStep;
(function (KioskStep) {
    KioskStep[KioskStep["SelectCountry"] = 0] = "SelectCountry";
    KioskStep[KioskStep["Login"] = 1] = "Login";
    KioskStep[KioskStep["LoginFailed"] = 2] = "LoginFailed";
    KioskStep[KioskStep["AccountExists"] = 3] = "AccountExists";
    KioskStep[KioskStep["SelectUser"] = 4] = "SelectUser";
    KioskStep[KioskStep["CreateAccount"] = 5] = "CreateAccount";
    KioskStep[KioskStep["Authenticated"] = 6] = "Authenticated";
    KioskStep[KioskStep["ShopClosed"] = 7] = "ShopClosed";
    KioskStep[KioskStep["ShopFull"] = 8] = "ShopFull";
    KioskStep[KioskStep["Loading"] = 9] = "Loading";
    KioskStep[KioskStep["Complete"] = 10] = "Complete";
    KioskStep[KioskStep["Error"] = 11] = "Error";
})(KioskStep || (KioskStep = {}));
export class CheckinKiosk extends Display {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: "",
            step: KioskStep.Loading,
            checkedInUsers: [],
            timeIdle: 0,
            userIsIdle: false,
            showGroup: true,
            showAddUser: false,
            addGuestOpen: false,
            isMobile: false,
            shopHours: {},
            shopName: "",
            businessNumber: "",
            region: this.props.region ? this.props.region : undefined,
            prefix: "",
            defaultRegion: undefined,
        };
    }
    updateConfiguration() {
        if (this.props.config) {
            this.theme = createMuiTheme({
                palette: {
                    type: 'dark',
                    background: this.props.config.backgroundColor,
                    text: {
                        primary: this.props.config.fontColor
                    },
                    primary: {
                        main: "#BF0D3E"
                    }
                },
                overrides: {
                    MuiTouchRipple: {
                        child: {
                            background: "#BF0D3E"
                        },
                        ripple: {
                            background: "#BF0D3E"
                        },
                    },
                    MuiButton: {
                        focusVisible: {
                            background: "#BF0D3E"
                        },
                        root: {
                            backgroundColor: "#BF0D3E"
                        },
                        containedSecondary: {
                            backgroundColor: "#BF0D3E",
                            '&:hover': {
                                backgroundColor: '#BF0D3E!important'
                            }
                        }
                    },
                    MuiDialog: {
                        paper: {
                            backgroundColor: '#111',
                            borderRadius: '15px'
                        }
                    },
                    MuiGrid: {
                        container: {
                            backgroundColor: 'transparent',
                            color: this.props.config.fontColor
                        },
                        item: {
                            backgroundColor: 'transparent',
                            color: this.props.config.fontColor
                        }
                    },
                    MuiInput: {
                        input: {
                            backgroundColor: "#fefefe",
                            borderRadius: 10
                        },
                        root: {
                            backgroundColor: "#fefefe",
                            borderRadius: 10
                        }
                    },
                    MuiInputBase: {
                        root: {
                            backgroundColor: "#fefefe",
                            borderRadius: 10,
                            color: '#222',
                        },
                        input: {
                            backgroundColor: "#fefefe",
                            borderRadius: 10,
                            color: '#222',
                        },
                        focused: {
                            backgroundColor: "#fefefe",
                            borderRadius: 10,
                            color: '#222'
                        }
                    },
                    MuiFilledInput: {
                        root: {
                            '&::before': {
                                borderBottom: 'none'
                            }
                        },
                        focused: {},
                        underline: {
                            '&::before': {
                                borderBottom: 'none'
                            },
                            '&::active': {
                                borderBottom: '2px solid #BF0D3E'
                            }
                        },
                    },
                    MuiSelect: {
                        filled: {
                            borderRadius: 10
                        },
                        selectMenu: {
                            backgroundColor: "#fefefe",
                            borderRadius: 10,
                            color: "#222"
                        },
                        select: {
                            'focus': {
                                backgroundColor: "#fefefe",
                                borderRadius: 10,
                                color: "#222"
                            }
                        }
                    },
                    MuiMenu: {
                        list: {
                            backgroundColor: "#fefefe",
                            borderRadius: 10,
                            color: "#222"
                        }
                    },
                    MuiMenuItem: {
                        root: {
                            backgroundColor: "#fefefe",
                            borderRadius: 10,
                            color: "#222"
                        },
                        selected: {
                            backgroundColor: "#BF0D3E",
                            borderRadius: 10,
                            color: "#222"
                        }
                    },
                }
            });
        }
        else {
            console.log('Failed to load display configuration');
        }
    }
    setErrorState(newState) {
        this.setState({ error: true });
    }
    loadData() {
        throw new Error("Method not implemented.");
    }
    update() {
        throw new Error("Method not implemented.");
    }
    componentWillMount() {
    }
    componentDidMount() {
        if (this.state.step == KioskStep.Loading && this.state.defaultRegion == undefined) {
            ApiUtils.get(`kiosk/GetShopCountry/${this.props.shopId}`).then(r => {
                switch (r.data["address"].country) {
                    case "Canada":
                        this.setState({ defaultRegion: "ca" });
                        break;
                    case "United States":
                        this.setState({ defaultRegion: "us" });
                        break;
                    case "New Zealand":
                        this.setState({ defaultRegion: "nz" });
                        break;
                    default:
                        this.setState({ defaultRegion: "ca" });
                        break;
                }
            });
        }
        this.updateConfiguration();
        this.setIsMobile();
        this.getIsOpen();
        //check every ten minutes
        setInterval(() => this.getIsOpen(), 60000 * 10);
        setInterval(() => this.renderOverflowOverlay(), 5);
        window.addEventListener('resize', this.setIsMobile.bind(this));
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.setIsMobile.bind(this));
    }
    lookupUser(number) {
    }
    setIsMobile() {
        this.setState({
            isMobile: window.innerWidth < 650
        });
    }
    getIsOpen() {
        ApiUtils.get(`kiosk/GetShopStatus/${this.props.shopId}`)
            .then(r => {
            // if shop is open - only change step if currently loading
            if (r.status == 200 && r.data && r.data["shopStatus"] == "Open" && this.state.step == KioskStep.Loading) {
                this.setState({
                    step: KioskStep.Login,
                    businessNumber: r.data["businessNumber"],
                    shopName: r.data["hours"]["shopName"],
                    shopHours: r.data["hours"]["openHours"],
                });
            }
            else if (r.status == 200 && r.data && r.data["shopStatus"] == "Closed") {
                this.setState({
                    step: KioskStep.ShopClosed,
                    businessNumber: r.data["businessNumber"],
                    shopName: r.data["hours"]["shopName"],
                    shopHours: r.data["hours"]["openHours"]
                });
            }
            else if (r.status == 200 && r.data && r.data["shopStatus"] == "Full") {
                this.setState({
                    step: KioskStep.ShopFull,
                    businessNumber: r.data["businessNumber"],
                    shopName: r.data["hours"]["shopName"],
                    shopHours: r.data["hours"]["openHours"]
                });
            }
        }).catch(e => {
            console.log("API GetHours Failed", this.props.shopId, e);
            // If api call fails upon startup set open as default, otherwise leave at current step
            if (this.state.step == KioskStep.Loading) {
                this.setState({
                    step: KioskStep.Loading,
                    shopHours: {
                        "open_time": "00:00:00",
                        "close_time": "23:59:59"
                    }
                });
                this.getIsOpen();
            }
        });
    }
    changeStep(newStep) {
        this.setState({
            step: newStep
        });
    }
    // TODO: Move this into another component/class
    onPasswordInput(event) {
        this.setState({
            password: event.target.value
        });
    }
    onBackClick() {
        this.setState({
            step: KioskStep.Login
        });
    }
    onRegisterClick() {
        this.setState({
            step: KioskStep.CreateAccount
        });
    }
    componentDidCatch(error, errorInfo) {
        // Set the error state so the error component is displayed
        this.setState({
            error: { error, errorInfo }
        });
    }
    onCheckin() {
        ApiUtils.get(`kiosk/GetAccountsForPhoneNumber/${this.state.phoneNumber}`)
            .then(r => {
            if (r.status == 200) {
                if (r.data) {
                    this.setState({
                        step: KioskStep.SelectUser,
                        currentUser: r.data
                    });
                }
                else {
                    this.setState({
                        step: KioskStep.LoginFailed
                    });
                }
            }
        });
    }
    onUserCheckin(user) {
        var checkedIn = this.state.checkedInUsers;
        if (this.state.currentUser)
            checkedIn.push(this.state.currentUser);
        var group = this.state.group || [];
        if (group.filter(g => g == user).length == 0) {
            group = group.concat(user);
        }
        this.setState({
            currentUser: user,
            checkedInUsers: checkedIn,
            group: group,
            showAddUser: false
        });
    }
    resetCheckinKioskState() {
        this.setState({
            phoneNumber: '',
            currentUser: undefined,
            checkedInUsers: [],
            timeIdle: 0,
            userIsIdle: false,
            showGroup: true,
            showAddUser: true,
            addGuestOpen: false,
            isMobile: window.innerWidth < 650 ? true : false,
            shopHours: this.state.shopHours,
            shopName: this.state.shopName,
            group: undefined,
            password: undefined,
            error: undefined,
            businessNumber: this.state.businessNumber,
            region: this.state.defaultRegion,
            step: KioskStep.Login,
            prefix: this.state.prefix,
        });
    }
    onBack() {
        if (this.state.checkedInUsers.length > 0) {
            // Group selection maybe?
        }
        else {
            this.resetCheckinKioskState();
        }
    }
    onWalkinCreate() {
        this.setState({ showAddUser: true });
    }
    onWalkinCancelled() {
        this.setState({ showAddUser: false });
    }
    getStep() {
        if (this.state.step == KioskStep.SelectCountry && (this.state.region != 'CA' && this.state.region != 'NZ')) {
            return React.createElement(CountrySelection, { onSelect: (selectedCountry) => this.setState({ region: selectedCountry, step: KioskStep.Login }) });
        }
        else if (this.state.step == KioskStep.Authenticated && this.state.currentUser)
            return React.createElement(CustomerCheckin, { shopId: this.props.shopId, config: this.props.config, currentUser: this.state.currentUser, availableUsers: this.state.group || [], checkedInUsers: this.state.checkedInUsers, onBackClick: () => this.onBack(), onChangeCurrentUser: (user) => this.onUserCheckin(user), onComplete: () => this.setState({ step: KioskStep.Complete, showGroup: false, showAddUser: false }), onCheckin: () => this.onWalkinCreate(), onCheckinCancelled: () => this.onWalkinCancelled(), isMobile: this.state.isMobile, businessNumber: this.state.businessNumber, onTimeOut: () => this.resetCheckinKioskState() });
        else if (this.state.step == KioskStep.CreateAccount)
            return React.createElement(RegistrationForm, { onBackClick: () => this.setState({ step: KioskStep.Login }), onRegister: (user) => this.setState({ step: KioskStep.Authenticated, currentUser: user, group: [user] }), phoneNumber: this.state.phoneNumber, isMobile: this.state.isMobile, disable: false, region: this.state.region || "CA" });
        else if (this.state.step == KioskStep.Complete) {
            var duration = parseInt(this.props.config.completionDuration || "3") * 1000;
            return React.createElement(CheckinCompletion, { backgroundImage: this.props.config.completionBackgroundImage, promotionImage: this.props.config.completionImage, duration: duration, checkedInUsers: this.state.checkedInUsers, onComplete: () => this.resetCheckinKioskState() });
        }
        else if (this.state.step == KioskStep.ShopClosed) {
            return React.createElement(ClosedShop, { closedShopText: this.props.config.closedShopText, shopHours: this.state.shopHours, shopName: this.state.shopName, shopFull: false });
        }
        else if (this.state.step == KioskStep.ShopFull) {
            return React.createElement(ClosedShop, { closedShopText: this.props.config.closedShopText, shopHours: this.state.shopHours, shopName: this.state.shopName, shopFull: true });
        }
        else if (this.state.step == KioskStep.Loading) {
            return React.createElement(Grid, { container: true, style: { minHeight: "100%", color: '#BF0D3E', backgroundColor: 'transparent' }, alignItems: "center" },
                React.createElement(CircularProgress, { style: { margin: "auto" } }));
        }
        else if (this.state.defaultRegion == undefined) {
            return React.createElement(Grid, { container: true, style: { minHeight: "100%", color: '#BF0D3E', backgroundColor: 'transparent' }, alignItems: "center" },
                React.createElement(CircularProgress, { style: { margin: "auto" } }));
        }
        else
            return React.createElement(LoginForm, { shopId: this.props.shopId, config: this.props.config, onRegisterClick: (number, disable, region) => this.setState({ step: KioskStep.CreateAccount, phoneNumber: number || "", region: region }), onAuthenticate: (result) => this.setState({ step: KioskStep.Authenticated, currentUser: result.currentUser, group: result.group }), isMobile: this.state.isMobile, region: this.state.region || this.state.defaultRegion || "CA", onComplete: this.resetCheckinKioskState });
    }
    showAddGuest() {
        this.setState({ addGuestOpen: true });
    }
    getGroupSelector() {
        var style = {
            padding: "0 40px",
            paddingLeft: 40
        };
        var mobileStyle = {
            padding: 0,
        };
        if (this.state.currentUser && this.state.group || this.state.step == KioskStep.CreateAccount) {
            return (React.createElement(Grid, { container: true, style: this.state.isMobile ? mobileStyle : style },
                React.createElement(Grid, { item: true, xs: this.state.isMobile ? 12 : 6 },
                    React.createElement("div", { className: "tg-kiosk-logo", style: { height: this.state.isMobile ? 150 : 200, marginTop: this.state.isMobile ? -140 : -245, margin: this.state.isMobile ? "unset" : "auto", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: "url('/Content/Images/TG_hero_logo-898x180.png')", backgroundPosition: this.state.isMobile ? "center" : "" } })),
                React.createElement(Grid, { item: true, xs: this.state.isMobile ? 12 : 6 },
                    React.createElement(GroupSelector, { showAddGuest: this.state.showAddUser, onAddGuest: () => this.showAddGuest(), users: this.state.group || [], currentUser: this.state.currentUser || { id: -1, firstName: "New Guest", lastName: "", phoneNumber: this.state.phoneNumber, checkedIn: false, email: "" }, checkedInUsers: this.state.checkedInUsers, isMobile: this.state.isMobile }))));
        }
    }
    onAddGuest(user) {
        var currentUsers = this.state.group || [];
        currentUsers.push(user);
        this.setState({
            group: currentUsers,
            addGuestOpen: false
        });
    }
    renderAddGuest() {
        return (React.createElement(Dialog, { open: this.state.addGuestOpen },
            React.createElement(DialogTitle, null,
                React.createElement("span", null, "Add Guest"),
                React.createElement(IconButton, { "arial-label": "close", onClick: () => this.setState({ addGuestOpen: false }), style: { position: "absolute", right: 0, top: 0 } },
                    React.createElement(CloseIcon, null))),
            React.createElement(DialogContent, null,
                React.createElement(AddGuestForm, { isMobile: this.state.isMobile, onSubmit: (fName, lName) => console.log(`${fName} ${lName}`), onAddTGUser: (user) => this.onAddGuest(user) }))));
    }
    renderLogo() {
        if (this.state.showGroup) {
            return (React.createElement(Grid, { item: true, xs: 12, className: `tg-kiosk-logo-container` }, this.getGroupSelector()));
        }
        else {
            return (React.createElement(React.Fragment, null));
        }
    }
    renderOverflowOverlay() {
        if (this.state.isMobile) {
            var body = document.getElementsByTagName('body')[0];
            var overlayDiv = document.getElementById("overflow-overlay");
            var bottomScrollPosition = body.scrollHeight - body.clientHeight - 80;
            if (body.scrollHeight > body.clientHeight && window.scrollY < bottomScrollPosition && this.state.step != KioskStep.Login) {
                overlayDiv === null || overlayDiv === void 0 ? void 0 : overlayDiv.classList.add("tg-overflow-y-overlay");
            }
            else {
                overlayDiv === null || overlayDiv === void 0 ? void 0 : overlayDiv.classList.remove("tg-overflow-y-overlay");
            }
        }
    }
    render() {
        var backgroundOverlay = this.state.step == KioskStep.Login ? '' : this.state.step == KioskStep.SelectCountry ? '' : 'linear-gradient( rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85) ),';
        var backgroundImage = this.state.step == KioskStep.Login ? this.props.config.loginBackgroundImage : this.props.config.backgroundImage;
        var backgroundStyle = this.props.config.backgroundImage ? `${backgroundOverlay} url('/api/v1/asset/view/${backgroundImage}')` : '#111';
        var styles = {
            backgroundImage: backgroundStyle,
            backgroundSize: this.state.step == KioskStep.Login ? "cover" : "cover",
            backgroundRepeat: 'no-repeat',
            minHeight: "100%",
            backgroundPosition: 'center'
        };
        if (this.state.error != null) {
            return (React.createElement(MuiThemeProvider, { theme: this.theme },
                React.createElement(Grid, { container: true, style: styles, alignContent: "center", alignItems: "center" },
                    React.createElement(KioskError, { message: this.props.config.onErrorMessage, error: this.state.error, onError: this.resetCheckinKioskState }))));
        }
        //if (this.state.step == KioskStep.Complete) {
        //    var duration = parseInt(this.props.config.completionDuration || "3") * 1000
        //    return <CheckinCompletion
        //        backgroundImage={this.props.config.completionBackgroundImage}
        //        promotionImage={this.props.config.completionImage}
        //        duration={duration}
        //    />
        //}
        return (React.createElement(MuiThemeProvider, { theme: this.theme },
            React.createElement(CssBaseline, null),
            React.createElement(Grid, { container: true, style: styles },
                this.renderLogo(),
                React.createElement(Grid, { item: true, xs: 6, style: { margin: "auto", textAlign: "center", display: (this.state.step == KioskStep.Login) ? "block" : "none" }, alignContent: "center", alignItems: "center" }),
                React.createElement(Grid, { item: true, xs: 12 },
                    this.renderAddGuest(),
                    this.getStep()),
                React.createElement("div", { id: "overflow-overlay" }))));
    }
}
